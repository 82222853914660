import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  mixins: [menuCardStyleMixin],
  computed: {
    ...mapState({
      dataPending: state => state.mapbox.dataPending,
      layersPending: state => state.mapbox.layersPending,
      selectedAssets: state => state.mapbox.selectedAssets,
      selectedAssetLayers: state => state.mapbox.selectedAssetLayers,
      selectedAssetProps: state => state.mapbox.selectedAssetProps,
      selectedAssetEprs: state => state.mapbox.selectedAssetEprs
    }),
    ...mapGetters({
      // Asset options
      assetOptions: 'mapbox/assetOptions',
      initialAssets: 'mapbox/initialAssets',
      // Asset layer options
      assetLayerOptions: 'mapbox/assetLayerOptions',
      initialAssetLayers: 'mapbox/initialAssetLayers',
      // Asset property options
      assetPropOptions: 'mapbox/assetPropOptions',
      initialAssetProps: 'mapbox/initialAssetProps',
      // Asset EPRS options
      assetEprsOptions: 'mapbox/assetEprsOptions',
      initialAssetEprs: 'mapbox/initialAssetEprs'
    })
  },
  methods: {
    ...mapMutations({
      setMapFilter: 'mapbox/setMapFilter',
      setSelectedAssets: 'mapbox/setSelectedAssets',
      setSelectedAssetLayers: 'mapbox/setSelectedAssetLayers',
      setSelectedAssetProps: 'mapbox/setSelectedAssetProps',
      setSelectedAssetEprs: 'mapbox/setSelectedAssetEprs'
    })
  }
}
