<template>
  <div class="mapbox-gl-control asset-control" :style="[offsetStyle, posStyle]">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      :left="attachLeft"
      offset-x
      z-index="10"
    >
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn
              class="pa-1 ma-2"
              height="auto"
              min-width="0"
              :disabled="!allowedActive"
              :color="!selectedNone ? 'primary darken-1' : 'white'"
              small
              v-on="{ ...tooltip, ...menu }"
              @click="initLabels"
            >
              <v-icon>$vuetify.icons.iconAsset</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Assets</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined flat>
        <v-card-text>
          <slot />
          <v-layout d-block :style="layoutStyle">
            <!-- Assets select -->
            <pibot-mapbox-asset-select
              v-if="assetOptions.length"
              :value="selectedAssets"
              :items="assetOptions"
              @change="updateSelectedAssets"
              @toggle="toggleAssets"
            />

            <v-divider class="my-3" v-if="assetOptions.length > 0 && assetLayerOptions.length > 0" />

            <!-- Asset layers select -->
            <pibot-mapbox-asset-select
              v-if="assetLayerOptions.length"
              :value="selectedAssetLayers"
              :items="assetLayerOptions"
              @change="updateSelectedAssetLayers"
              @toggle="toggleAssetLayers"
              label="Asset related layers"
            />

            <v-divider class="my-3" v-if="(assetOptions.length > 0 || assetLayerOptions.length > 0) && assetPropOptions.length > 0" />

            <!-- Asset properties select -->
            <pibot-mapbox-asset-select
              v-if="assetPropOptions.length"
              :value="selectedAssetProps"
              :items="assetPropOptions"
              @change="updateSelectedAssetProps"
              @toggle="toggleAssetProps"
              label="Properties"
            />

            <v-divider class="my-3" v-if="(assetOptions.length > 0 || assetLayerOptions.length > 0 || assetPropOptions.length > 0) && assetEprsOptions.length > 0" />

            <!-- Asset EPRS layers select -->
            <pibot-mapbox-asset-select
              v-if="assetEprsOptions.length"
              :value="selectedAssetEprs"
              :items="assetEprsOptions"
              @change="updateSelectedAssetEprs"
              @toggle="toggleAssetEprs"
              label="EPRS"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import mapboxFiltersMixin from '@/utils/mixins/mapbox/mapboxFilters.mixin.js'
import assetControlMixin from '../utils/mixins/assetControl.mixin.js'

console.error('Remove duplicate code')
console.error('selectedAll/Some/None functions should check in all types of asset selectors')

export default {
  name: 'pibot-mapbox-asset-control',
  mixins: [
    positionStyleMixin,
    offsetStyleMixin,
    mapboxBasicsMixin,
    mapboxLazyMixin,
    mapboxFiltersMixin,
    assetControlMixin
  ],
  components: {
    'pibot-mapbox-asset-select': () => import('./Select')
  },
  data () {
    return {
      initialized: false,
      menu: false,
      labelsInitialized: false,
      unwatchLayers: null
    }
  },
  computed: {
    selectedNone () {
      return this.selectedAssets.length === 0 && this.selectedAssetLayers.length === 0 && this.selectedAssetProps.length === 0
    },
    allowedActive () {
      return (this.assetOptions.length || this.assetLayerOptions.length || this.assetPropOptions.length)
    }
  },
  methods: {
    /**
     * Initialize the spacing of the labels on the v-autocomplete components which
     * have the 'outlined' styling.
     * This is done by emptying the value and setting the value back to it's
     * original value after the component has become visible.
     * This causes the label to recalculate it's width.
     */
    initLabels () {
      if (this.labelsInitialized) return // No need to do it again
      if (!this.selectedAssets.length && !this.selectedAssetLayers.length) return // It is already empty

      // Save the original values
      const originAssets = JSON.parse(JSON.stringify(this.selectedAssets))
      const originAssetLayers = JSON.parse(JSON.stringify(this.selectedAssetLayers))
      const originAssetProps = JSON.parse(JSON.stringify(this.selectedAssetProps))
      // Empty the current values
      this.setSelectedAssets([])
      this.setSelectedAssetLayers([])
      this.setSelectedAssetProps([])

      // Restore the original values after the component has become visible
      setTimeout(() => {
        this.setSelectedAssets(originAssets)
        this.setSelectedAssetLayers(originAssetLayers)
        this.setSelectedAssetProps(originAssetProps)
        this.labelsInitialized = true
      }, 280) // Menu animation takes 280ms to complete
    },
    // ----------------------------------------------------- Assets -----------------------------------------------------
    updateSelectedAssets (selectedAssets) {
      this.setSelectedAssets(selectedAssets)
      for (const option of this.assetOptions) {
        const willBeVisible = selectedAssets.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }

      // filters
      // create array of assets to filter on
      const allAssets = this.assetOptions.map(o => o.value)
      const assetsToFilter = []
      for (const asset of allAssets) {
        if (!selectedAssets.includes(asset)) {
          // Get label of current option
          const label = this.assetOptions.find(o => o.value === asset).text
          assetsToFilter.push(label)
          assetsToFilter.push(asset.toUpperCase()) // BC
        }
      }

      const mapFilter = assetsToFilter.length ? [] : null
      if (mapFilter) {
        for (const asset of assetsToFilter) {
          mapFilter.push({
            'Pipeline Name': asset,
            Pipeline: asset,
            Name: asset
          })
        }
      }

      // set map state filter
      this.handleUnifiedFilter(this.indLayerIds, mapFilter)
    },
    toggleAssets () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAssets.length === this.assetOptions.length) newValue = []
        else newValue = this.assetOptions.map(o => o.value)

        this.updateSelectedAssets(newValue)
      })
    },
    // ----------------------------------------------------- Asset Layers -----------------------------------------------------
    updateSelectedAssetLayers (selectedAssetLayers) {
      this.setSelectedAssetLayers(selectedAssetLayers)
      for (const option of this.assetLayerOptions) {
        const willBeVisible = selectedAssetLayers.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleAssetLayers () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAssetLayers.length === this.assetLayerOptions.length) newValue = []
        else newValue = this.assetLayerOptions.map(o => o.value)

        this.updateSelectedAssetLayers(newValue)
      })
    },
    // ----------------------------------------------------- Asset Properties -----------------------------------------------------
    updateSelectedAssetProps (selectedAssetProps) {
      this.setSelectedAssetProps(selectedAssetProps)
      for (const option of this.assetPropOptions) {
        const willBeVisible = selectedAssetProps.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleAssetProps () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAssetProps.length === this.assetPropOptions.length) newValue = []
        else newValue = this.assetPropOptions.map(o => o.value)

        this.updateSelectedAssetProps(newValue)
      })
    },
    // ----------------------------------------------------- Asset EPRS -----------------------------------------------------
    updateSelectedAssetEprs (selectedAssetEprs) {
      this.setSelectedAssetEprs(selectedAssetEprs)
      for (const option of this.assetEprsOptions) {
        const willBeVisible = selectedAssetEprs.includes(option.value)

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    toggleAssetEprs () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAssetEprs.length === this.assetEprsOptions.length) newValue = []
        else newValue = this.assetEprsOptions.map(o => o.value)

        this.updateSelectedAssetEprs(newValue)
      })
    }
  },
  created () {
    /**
     * Set initially 'visible' options as selectedAssets on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch(
      'layersPending',
      layersPending => {
        if (!this.initialized && !layersPending) {
          this.setSelectedAssets(this.initialAssets)
          this.setSelectedAssetLayers(this.initialAssetLayers)
          this.setSelectedAssetProps(this.initialAssetProps)
          this.setSelectedAssetEprs(this.initialAssetEprs)
          this.initialized = true

          // Unwatch after init
          if (this.unwatchLayers) this.unwatchLayers()
          else console.error("unable to 'unwatch' 'layersPending'")
        }
      },
      {
        deep: true
      }
    )
  }
}
</script>
